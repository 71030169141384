var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2aTIOR52_A2fDhWoodxzc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { addEventProcessor, init } from '@sentry/nextjs'
import { ZodProcessor, defaultOptions } from './sentry.common'

// NOTE: the client-side (browser) Sentry integration is not needed in webhooks – in the future, this file can safely be removed

init({
  ...defaultOptions,
})

addEventProcessor(ZodProcessor)
